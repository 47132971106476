import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocImage, DocRoot} from "../Docs";

function RsocInuvoIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center"}}>
                <a href="https://inuvo.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/inuvo.svg" alt="Inuvo logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Inuvo RSOC with Skro?"/>
            <Box>
                <h2>Who we are</h2>
                <p>
                    <a href="https://inuvo.com" target="_blank" rel="noreferrer">
                        Inuvo
                    </a> is on a mission to revolutionize advertising by harnessing the power of AI to deliver
                    impactful brand messages that safeguard consumer privacy and uphold brand integrity.
                </p>
                <h2>About Inuvo</h2>
                <p>
                    Inuvo, Inc. (NYSE American: INUV) is an Artificial Intelligence based technology company that
                    designs, develops and patents proprietary advertising solutions that it sells through agencies,
                    directly to brands and integrated into advertising platforms.
                </p>
                <p>
                    The company’s core differentiators include its large language generative AI, designed to locate and
                    target online audiences without using consumer data, cookies or identifiers and its machine learning
                    predictive media mix AI, designed to empower CMO’s with the ability to confidently and statistically
                    optimize advertising spend across the omnichannel.
                </p>
                <h2>Inuvo RSOC Integration</h2>
                <p>
                    Skro integration with Inuvo gives you the ability to sync earnings data that has been
                    adjusted after different sources.
                </p>
                <ul>
                    <li>Revenue updates every hour.</li>
                    <li>Revenue updates for the last 3 days.</li>
                </ul>

                <h2>Step 1. Add integration</h2>
                <DocDivider/>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>
                        Scroll down and click on the <strong>Add</strong> button in <strong>Revenue
                        Sync - RSOC</strong> section.
                    </li>
                </ul>
                <DocImage src="/images/docs/inuvo-int-1.png" alt="Inuvo RSOC integration step 1"
                />
                <ul>
                    <li>Click on the <strong>Inuvo</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/inuvo-int-2.png" alt="Inuvo RSOC integration step 2"
                />
                <ul>
                    <li><strong>Integration name</strong> Give a name to your integration.</li>
                    <li>
                        <strong>S3 Bucket Name</strong> info you must ask from your Inuvo
                        account manager or write to Inuvo support.
                    </li>
                    <li>
                        <strong>S3 Bucket Region</strong> info you must ask from your Inuvo
                        account manager or write to Inuvo support.
                    </li>
                    <li>
                        <strong>AWS Access Key</strong> info you must ask from your Inuvo
                        account manager or write to Inuvo support.
                    </li>
                    <li>
                        <strong>AWS Access Secret</strong> info you must ask from your Inuvo
                        account manager or write to Inuvo support.
                    </li>
                    <li>
                        <strong>Net Revenue %</strong> It's a net revenue percentage. Example value: <strong>90</strong>
                    </li>
                    <li><strong>Inuvo Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not mandatory field, but you can see a revenue and
                        conversions info in a different columns in your Skro reports.
                    </li>
                </ul>
                <DocImage src="/images/docs/inuvo-int-3.png" alt="Inuvo RSOC integration step 3"
                />
                <ul>
                    <li>Fill the form and click <strong>Save & Close</strong></li>
                </ul>

                <h2>Step 2. How to add Inuvo RSOC offer URL into Skro?</h2>
                <DocDivider/>

                <p>1. Go to <strong>Offers</strong> page.</p>
                <p>2. Click on <strong>Create</strong> button and add your offer URL and fill other fields.</p>

                <DocImage src="/images/docs/system1-rsoc-4.jpg" alt="System1 RSOC event purchase" sx={{mb: 3}}/>

                <p>This is an example offer URL which goes to Skro:</p>
                <Alert severity="success" variant="filled" color="success">
                    {`https://example.com/article/rower-elektryczny-na-raty-pozabankowe-nowoczesne-rozwizanie?utm_term=Rower+Elektryczny+Na+Raty+Pozabankowe,Rower+Elektryczny+Na+Raty+Bez+Zaświadczeń,Rower+Elektryczny,Rower+Na+Raty+Bez+Bik&utm_content=rower+elektryczny+na+raty+dowiedz+się+więcej&serplayout=centered&camp_id=333222&clickId={clickId}`}
                </Alert>

                <p>
                    Offer URL has 2 important parameters:
                </p>
                <p>
                    1. <strong>&camp_id=333222</strong> - is a Inuvo Campaign ID.
                </p>
                <p>
                    2. <strong>{`&clickId={clickId}`}</strong> - is a Skro Click ID to track conversions and sync
                    revenue.
                </p>

                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Note: If you are encoding Offer URL, do not encode Skro click id macro {clickId}`}
                </Alert>

                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 1, mb: 4}}>
                    Note: This example has Facebook and TikTok compatibility with Conversion API.
                </Alert>

                <h2>Step 3. Map Inuvo Campaign ID parameter name and macro.</h2>
                <DocDivider/>
                <p>
                    1. To match Inuvo campaign and Skro campaign it is very important to create a mapping for Inuvo
                    campaign id.
                </p>
                <p>
                    2. Go to <strong>Traffic Sources</strong> and <strong>edit</strong> your source.
                </p>
                <p>
                    3. In our example I'm going to add Inuvo Campaign ID mapping for Facebook traffic source:
                </p>

                <p><strong>REQUIRED VALUES ARE:</strong></p>
                <p>
                    Name: <strong>Inuvo Campaign ID</strong>
                </p>
                <p>
                    Parameter: <strong>camp_id</strong>
                </p>
                <p>
                    Placeholder: <strong>{`{camp_id}`}</strong>
                </p>

                <DocImage src="/images/docs/inuvo-int-4.png" alt="Inuvo RSOC event purchase"/>

                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    Note: You will need to add Inuvo Campaign ID manually each time when placing a Skro campaign
                    tracking link to your traffic source for approval.
                </Alert>

                <p>
                    This is an example of Skro Campaign Tracking link which goes to Facebook:
                </p>
                <Alert severity="success" variant="filled" color="success">
                    {`https://example.com/campaign/b843e659-65e1-4d04-aeaf-ddddddddd?ad_id={{ad.id}}&ad_name={{ad.name}}&adset_id={{adset.id}}&adset_name={{adset.name}}&campaign_id={{campaign.id}}&campaign_name={{campaign.name}}&placement={{placement}}&site_source_name={{site_source_name}}&camp_id=222333`}
                </Alert>

                <h2>Step 4. Create a Custom Conversions for Inuvo events. (OPTIONAL)</h2>
                <DocDivider/>
                <p>
                    With custom conversion events feature it is possible to track revenue and clicks on a
                    different column. For Inuvo we are going to create event <strong>Purchase</strong>.
                </p>
                <DocImage src="/images/docs/system1-purchase.png" alt="System1 RSOC event purchase"/>

                <p>
                    Give your postback URL to your Inuvo account manager:
                </p>
                <Alert color="info" variant="filled">
                    {`https://your_tracking_domain/postback?clickId={clickId}&ce=Purchase`}
                </Alert>

                <p>
                    If you are using regular postback URL:
                </p>
                <Alert color="info" variant="filled" sx={{mt: 2, mb: 4}}>
                    {`https://your_tracking_domain/postback?clickId={clickId}`}
                </Alert>

                <DocDivider/>
                <p>Integration setup is done and your are ready to sync your earnings data.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default RsocInuvoIntegration;
